<template>
  <div>
    <v-dialog v-model="dialogServicoForm" v-if="dialogServicoForm" max-width="800px">
      <v-card>
        <v-card-title>
          <span v-if="!servico.id" class="headline">{{ $t('label.adicionar_item') }}</span>
          <span class="headline" v-else>{{ $t('label.editar_item') }}</span>
        </v-card-title>
        <v-form ref="form" lazy-validation>
          <v-container grid-list-md>
            <v-row>
              <v-col cols="8" sm="4">
                <v-select
                  v-model.trim="servico.tipo"
                  :items="tipos"
                  :disabled="somenteLeitura"
                  :label="`${$tc('label.tipo', 1)} *`"
                  item-text="texto"
                  item-value="valor"
                  dense
                  :rules="[rules.required]">
                </v-select>
              </v-col>
              <v-col cols="8" sm="4">
                <v-text-field
                  id="descricao"
                  v-model="servico.descricao"
                  :rules="[rules.required]"
                  :disabled="somenteLeitura"
                  :counter="500"
                  maxlength="500"
                  rows="1"
                  auto-grow
                  dense
                  :label="`${$tc('label.descricao')} *`"/>
              </v-col>
              <v-col cols="8" sm="4">
                <v-text-field
                  id="ncm"
                  v-model="servico.ncm"
                  dense
                  :counter="50"
                  maxlength="50"
                  :disabled="somenteLeitura"
                  :label="`${$tc('label.ncm')}`"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="1" sm="4">
                <v-text-field
                  id="quantidade"
                  type="number"
                  :label="`${$tc('label.quantidade')} *`"
                  :rules="[rules.required, rules.valorPositivo]"
                  dense
                  v-model="servico.quantidade"
                  @input="calcularTotal"/>
              </v-col>
              <v-col cols="8" sm="4">
                <input-money
                  :label="`${$tc('label.valor_unitario')} *`"
                  :required="false"
                  :rules="[rules.required, rules.valorPositivo]"
                  @input="calcularTotal"
                  v-model="servico.vlrunitario">
                </input-money>
              </v-col>
              <v-col cols="8" sm="4">
                <input-money
                  :label="$t('label.valor_total')"
                  disabled
                  dense
                  v-model="servico.valorTotal">
                </input-money>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text
                   color="primary"
                   @click="close">{{ $t('label.cancelar') }}</v-btn>
            <v-btn text
                   color="primary"
                   @click="adicionarServico" >{{ !isEdicao ? $t('label.adicionar') : $t('label.salvar') }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import InputMoney from '@/shared-components/inputs/InputMoney';
import InputNumber from '@/shared-components/inputs/InputNumber';

export default {
  name: 'PlanejamentoAcaoFormModalDetalhesAcao',
  components: {
    InputMoney,
    InputNumber,
  },
  props: {
    somenteLeitura: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      servico: { vlrunitario: 0 },
      tipos: [
        { texto: 'Purchase', valor: 'PURCHASE' },
        { texto: 'Framework', valor: 'FRAMEWORK' },
      ],
      dialogServicoForm: false,
      isEdicao: false,
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        valorPositivo: (v) => (v && v > 0) || this.$t('errors.valor.negativo'),
      },

    };
  },
  methods: {
    open(item) {
      if (item) {
        this.servico = item;
        this.isEdicao = true;
        this.calcularTotal();
      } else {
        this.servico = { vlrunitario: 0 };
      }
      this.dialogServicoForm = true;
    },
    close() {
      this.isEdicao = false;
      this.dialogServicoForm = false;
    },
    calcularTotal() {
      this.servico.valorTotal = this.servico.quantidade * this.servico.vlrunitario;
    },
    adicionarServico() {
      if (!this.$refs.form.validate()) return;
      this.dialogServicoForm = false;
      if (!this.isEdicao) {
        this.servico.descricao = this.servico.descricao.trim();
        if (this.servico.ncm) {
          this.servico.ncm = this.servico.ncm.trim();
        }
        this.$emit('SALVAR_SERVICO', this.servico);
      }
      this.$emit('RECALCULAR_TOTAL');
      this.isEdicao = false;
    },
  },
};
</script>
