<style>
  .PlanejamentoAcaoForm {
    margin-bottom: 70px;
    }
  .PlanejamentoAcaoForm .card-title {
    font-size: 18px;
    font-weight: 500;
  }
  .PlanejamentoAcaoForm .card-subtitle {
    font-size: 16px;
    font-weight: 400;
  }
</style>
<template>
  <div class="PlanejamentoAcaoForm" v-if="!!configuracao">

    <component v-for="(p, index) in componentesPassos" :key="index"
      :is="p.componente"
      :ref="p.nome"
      v-if="carregouEstrutura"
      v-show="exibePasso(p.componente)"
      :tipo-acao="tipoAcao"
      :configuracao="configuracao"
      :acao-edicao="acaoEdicao"
      :nova-acao="novo"
      :somente-leitura="naoPodeEditar"
      :passoAberto="passoAberto"
      :edicao="edicao"
      :passos-habilitados="passosHabilitados"
      :get-objeto-outro-passo="getObjetoOutroPasso"
      @PlanejamentoAcaoForm__AtualizaIndTemEvidencia="atualizaIndTemEvidencia"
      @PlanejamentoAcaoForm__evento="executarEvento"
      @PlanejamentoAcaoForm__cancelar="cancelarEdicao">
    </component>

    <div class="PlanejamentoAcaoForm_Acoes" v-if="carregouEstrutura">
      <planejamento-acao-form-rodape
        ref="rodapeAcoes"
        :definicao-passos="planejamentoDefinicaoPassos"
        :edicao="edicao"
        :somente-leitura="naoPodeEditar"
        :acao-edicao="acaoEdicao"
        :configuracao="configuracao"
        :get-componentes-passos="getComponentesPassos"
        :valida-componente-passo="validaComponentePasso"
        :valida-componente-passo-salvar="validaComponentePassoSalvar"
        :ind-tem-evidencia="indTemEvidencia"
        @PlanejamentoAcaoFormRodape__MudaPasso="mudaPasso"
        @PlanejamentoAcaoForm__antesIrProximo="executarEvento"
        @PlanejamentoAcaoForm__executarEventoEncadeado="montarExecucaoEncadeada"
        />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import PlanejamentoAcaoFormRodape from '@/spa/planejamento-acao/form/PlanejamentoAcaoFormRodape';
import PlanejamentoAcaoFormPassosMixin from '@/spa/planejamento-acao/form/PlanejamentoAcaoFormPassosMixin';
import AcaoConfiguracao from '../AcaoConfiguracao';

export default {
  components: {
    PlanejamentoAcaoFormRodape,
  },
  mixins: [PlanejamentoAcaoFormPassosMixin],
  computed: {
    ...mapGetters('usuario', [
      'usuarioLogado',
    ]),
    carregouEstrutura() {
      return this.tipoAcao != null && this.configuracao != null;
    },
    naoPodeEditar() {
      return this.somenteLeitura
          || this.statusAcao !== 'EM_CADASTRO';
    },
    chavePassosHabilitados() {
      return Object.keys(this.passosHabilitados).filter((k) => this.passosHabilitados[k].habilitado);
    },
  },
  props: {
    edicao: Boolean,
    novo: Boolean,
    somenteLeitura: Boolean,
  },
  data() {
    return {
      tipoAcaoResource: this.$api.tipoAcao(this.$resource),
      configuracaoResource: this.$api.planejamentoAcaoConfiguracao(this.$resource),
      planejamentoAcaoListagemResource: this.$api.planejamentoAcaoListagem(this.$resource),

      configuracao: null,
      tipoAcao: null,
      acaoEdicao: {},
      statusAcao: 'EM_CADASTRO',

      passoAberto: 1,

      tiposVerbaUsuarioLogado: [],

      indTemEvidencia: false,
    };
  },
  methods: {
    cancelarEdicao(forceVoltaParaLista = false) {
      this.$refs.rodapeAcoes.cancelar(forceVoltaParaLista);
    },
    exibePasso(componente) {
      return this.componentesPassos
        .map((p) => p.componente)
        .indexOf(componente) + 1 === this.passoAberto;
    },
    getObjetoOutroPasso(passo) {
      const componente = this.$refs[passo][0];
      if (componente.getObjetoPasso) {
        return componente.getObjetoPasso();
      }
      return null;
    },
    mudaPasso(passo) {
      if (passo > this.passoAberto) {
        this.avancaPasso(passo);
      }
      this.passoAberto = passo;
    },
    avancaPasso(passo) {
      const ref = this.chavePassosHabilitados[passo - 1];
      this.$refs[ref][0].abrePasso();
    },
    validaComponentePasso() {
      const ref = this.chavePassosHabilitados[this.passoAberto - 1];
      const componente = this.$refs[ref][0];
      if (componente.valida) {
        return componente.valida();
      }
      return true;
    },
    validaComponentePassoSalvar(isSolicitandoAprovacao) {
      const passos = this.chavePassosHabilitados;
      let valido = true;
      for (let i = 0; i < passos.length && valido; i += 1) {
        const componente = this.$refs[passos[i]][0];

        if (componente.validaSalvar) {
          valido = componente.validaSalvar(isSolicitandoAprovacao);
        }
      }

      return valido;
    },
    carregarAcao(idAcao) {
      this.idAcao = idAcao;

      this.planejamentoAcaoListagemResource.buscarAcao({ idAcao })
        .then((res) => {
          this.acaoEdicao = { ...res.data };
          this.formataCamposADS();
          this.statusAcao = this.acaoEdicao.passoGeral.status;
          return this.acaoEdicao.passoGeral.idAcaoTipo;
        }).then((idAcaoTipo) => {
          Promise.all([
            this.carregarTipoAcao(idAcaoTipo),
            this.carregarConfiguracao(idAcaoTipo),
          ]).then(() => {
            setTimeout(() => this.$refs.rodapeAcoes.carregaInformacoesFluxo(idAcao));
          });
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    carregarConfiguracao(idTipoAcao) {
      const { idAcao } = this;

      const promise = this.novo
        ? this.configuracaoResource.buscarConfigVigente({ idTipoAcao })
        : this.configuracaoResource.buscarConfigSnapshot({ idAcao });

      return promise.then((res) => {
        this.configuracao = new AcaoConfiguracao(res.data);
      })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    carregarTipoAcao(idTipoAcao) {
      return this.tipoAcaoResource.buscarTipoAcaoComConfiguracao({ idTipoAcao })
        .then((res) => {
          this.tipoAcao = res.data;
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    getComponentesPassos() {
      const componentes = [];
      this.componentesPassos.map((c) => c.nome).forEach((ref) => {
        const [referencia] = this.$refs[ref];
        componentes[ref] = referencia;
      });
      return componentes;
    },
    carregarSeUsuarioPossuirAcesso(idAcao) {
      this.planejamentoAcaoListagemResource.verificarSeUsuarioPossuiAcesso({ idAcao })
        .then((res) => {
          if (res.data) {
            this.carregarAcao(idAcao);
          } else {
            this.$router.push({ name: 'inicio' });
            this.$toast('Acesso negado');
          }
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    atualizaIndTemEvidencia(indicador) {
      this.indTemEvidencia = indicador;
    },
    formataCamposADS() {
      this.acaoEdicao.passoGeral.valor_ads = this.acaoEdicao.passoGeral.valorAds || null;
      this.acaoEdicao.passoGeral.codigo_campanha = this.acaoEdicao.passoGeral.codigoCampanha || null;
      this.acaoEdicao.passoGeral.codigo_ads_trade = this.acaoEdicao.passoGeral.codigoAdsTrade || null;
    },
  },
  mounted() {
    if (this.novo) {
      const { idTipoAcao } = this.$route.params;
      this.carregarTipoAcao(idTipoAcao);
      this.carregarConfiguracao(idTipoAcao);
    } else {
      const { idAcao, from } = this.$route.params;
      if (!from) {
        this.carregarSeUsuarioPossuirAcesso(idAcao);
      } else {
        this.carregarAcao(idAcao);
      }
    }
  },
};
</script>
