<template>
  <div class="PlanejamentoAcaoFormApuracao" v-if="ordenacaoFormulario.length">
    <div class="mt-3 mb-2 title-float accent--text">
      {{$tc('label.excecao', 2)}}
    </div>
    <v-card>
      <v-container fluid grid-list-md>
        <metadados-container-layout
          v-if="parametrosAplicados && ordenacaoFormulario.length"
          :metadados-entidade="metadadosParametrizado"
          :layout-class="layoutClass"
          :input-layout="inputLayout"
          :objeto="acao"
          :campos-formulario="camposFormulario"
          :ordenacao-campos="ordenacaoFormulario"
          :explode-hierarquia="true"
          :somente-leitura="somenteLeitura"
          ref="container">
        </metadados-container-layout>
      </v-container>
    </v-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import MetadadosContainerLayout from '@/shared-components/metadados/MetadadosContainerLayout';
import PlanejamentoAcaoFormCamposDefinicao from './PlanejamentoAcaoFormCamposDefinicao';

export default {
  props: {
    somenteLeitura: Boolean,
    configuracao: {
      type: Object,
      required: true,
    },
  },
  mixins: [
    PlanejamentoAcaoFormCamposDefinicao,
  ],
  components: {
    MetadadosContainerLayout,
  },
  computed: {
    ...mapGetters('metadados', [
      'getAcaoMetadado',
      'getProdutoMetadado',
    ]),
    ordenacaoFormulario() {
      const { excecaoCampos } = this.configuracao;
      return excecaoCampos;
    },
  },
  data() {
    return {

    };
  },
  methods: {
    aplicaParametrizacoes() {
      this.metadadosParametrizado = this.configuracao.aplicaParametrizacao(this.getAcaoMetadado);
    },
    formataExcecoes(objetoFormatado) {
      if (this.ordenacaoFormulario.length) {
        const campoProduto = {
          ...this.$refs.container.getValoresCamposPadrao(),
        };
        objetoFormatado.idProduto = campoProduto.idProduto;

        objetoFormatado.mapaExcecoes = {
          ...this.$refs.container.getValoresDependencias(),
        };
      }
    },
    montaObjetoAcao(objeto) {
      this.acao = {
        ...objeto.passoComportamento,
        ...objeto.passoComportamento.mapaExcecoes,
      };
    },
  },
  mounted() {
    this.aplicaParametrizacoes();

    this.parametrosAplicados = true;
  },
};
</script>
