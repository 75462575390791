<template>
  <v-col cols="12" md="12" :lg="isVerbaVariavel ? 12 : 6" style="text-align:right">
    <slot name="acoes-padrao" />
    <v-btn @click="salvarAntesDeSolicitar()"
     class="mr-3"
      dark
      color="red"
      v-show="ultimoPasso && podeSolicitarAprovacao"
      :disabled="!permiteSalvar">
      {{ $t('label.solicitar_aprovacao') }}
    </v-btn>
    <v-btn @click="abrirJustificativaAprovar()"
     class="mr-3"
      dark
      color="primary"
      v-show="ultimoPasso && podeAprovar">
      {{ $t('label.aprovar') }}
    </v-btn>
    <v-btn @click="abrirJustificativaReprovar()"
     class="mr-3"
      dark
      color="red"
      v-show="ultimoPasso && podeAprovar">
      {{ $t('label.reprovar') }}
    </v-btn>
    <v-btn @click="abrirJustificativaAnalise()"
     class="mr-3"
      dark
      color="tertiary lighten-1"
      v-show="ultimoPasso && podeAprovar">
      {{ $tc('label.enviar_analise', 1) }}
    </v-btn>
    <confirm
      ref="modalConfirmacao"
      :message="msgConfirmacao"
      :persistent="true"
      hide-disagree
      :agree-label="$t('label.ok')"/>
    <planejamento-acao-form-usuario-cliente
      ref="modalUsuarioCliente"
      :title="$tc('title.selecao_aprovador_fornecedor', 1)"
      :passoCliente="passoCliente"
      @selecionarUsuarioCliente="selecionaAprovadorPassoClienteFornecedor"/>
    <planejamento-acao-justificativa
      ref="modalJustificativa"
      :id-acao="idAcao"
      :obrigatorio="justificativaObrigatoria"
      :salvar-justificativa="actionJustificativa"
      :is-aprovacao="isAprovacao" />
    <modal-redirecionamento
      ref="modalRedirecionamento"
      @INICIAR_FLUXO="iniciarFluxo">
    </modal-redirecionamento>
  </v-col>
</template>
<script>
import { iniciaFluxo } from '@/common/resources/workflow-acao-redirecionamento';
import PlanejamentoAcaoFormUsuarioCliente from '@/spa/planejamento-acao/form/PlanejamentoAcaoFormUsuarioCliente';
import PlanejamentoAcaoJustificativa from '@/spa/planejamento-acao/PlanejamentoAcaoJustificativa';
import Confirm from '@/shared-components/dialog/Confirm';
import ModalRedirecionamento from '@/shared-components/workflow/ModalRedirecionamento';

export default {
  props: {
    ultimoPasso: Boolean,
    permiteSalvar: Boolean,
    acaoEdicao: Object,
    edicao: Boolean,
    isVerbaVariavel: {
      type: Boolean,
      default: false,
    },
    acaoSalvar: {
      type: Function,
      default: () => ({}),
    },
    isModalidadeValorFixo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      workflowAcaoResource: this.$api.workflowAcao(this.$resource),
      workflowAcaoMagaluResource: this.$api.workflowAcaoMagalu(this.$resource),

      solicitanteFluxo: false,
      passoCliente: {},
      aprovadorFluxo: false,
      justificativaObrigatoria: false,
      actionJustificativa() {},
      idAcao: null,
      msgConfirmacao: null,
      isAprovacao: false,
    };
  },
  components: {
    PlanejamentoAcaoFormUsuarioCliente,
    PlanejamentoAcaoJustificativa,
    Confirm,
    ModalRedirecionamento,
  },
  computed: {
    podeSolicitarAprovacao() {
      return this.edicao && this.acaoEdicao.passoGeral
        && this.acaoEdicao.passoGeral.status === 'EM_CADASTRO' && this.solicitanteFluxo;
    },
    podeAprovar() {
      return this.acaoEdicao.passoGeral
        && this.acaoEdicao.passoGeral.status === 'AGUARDANDO_APROVACAO' && this.aprovadorFluxo;
    },
  },
  methods: {
    carregaInformacoesFluxo(idAcao) {
      this.idAcao = Number(idAcao);
      if (this.acaoEdicao.passoGeral.status === 'EM_CADASTRO') {
        this.verificarUsuarioSolicitante(idAcao);
      } else {
        this.verificarUsuarioAprovador(idAcao);
        this.buscarStatusFluxo(idAcao);
      }
    },
    verificarUsuarioSolicitante(idAcao) {
      if (this.acaoEdicao.passoGeral && this.acaoEdicao.passoGeral.status === 'EM_CADASTRO') {
        this.workflowAcaoResource.solicitante({ idAcao })
          .then((res) => {
            this.solicitanteFluxo = res.data.usuarioHabilitado;
          });
      }
    },
    solicitarAprovacao() {
      const resource = this.workflowAcaoResource.buscaPassosFornecedor;
      const { idAcao } = this.$route.params;
      resource({ idAcao })
        .then((response) => {
          const passosCliente = response.data;
          if (passosCliente.length === 0 || !this.acaoEdicao.passoGeral.indPossuiPassoFornecedor) {
            this.iniciarFluxo();
          } else if (passosCliente.length > 1) {
            const msg = this.$t('errors.acordo.multiplos_passos_fornecedor');
            this.$toast(msg);
          } else if (!passosCliente[0].usuarios.length) {
            this.msgConfirmacao = this.$t('message.nao_tem_usuario_fornecedor_aprovador');
            setTimeout(() => this.$refs.modalConfirmacao.open());
          } else {
            // Implementado suporte para apenas 1 passo cliente/fornecedor
            const [passoCli] = passosCliente;
            this.passoCliente = passoCli;
            this.$refs.modalUsuarioCliente.open();
          }
        });
    },
    abreModalRedirecionamento() {
      this.workflowAcaoMagaluResource.buscarFluxoAcao()
        .then((res) => {
          this.fluxo = res.data;
          this.$refs.modalRedirecionamento.fluxo = this.fluxo;
          this.$refs.modalRedirecionamento.open();
        })
        .catch((err) => {
          this.$toast(this.$t(err));
        });
    },
    iniciarFluxo(dados = null) {
      const params = {
        idAcao: this.$route.params.idAcao,
      };

      if (dados != null) {
        const { fluxo } = dados;

        if (fluxo != null) {
          const idsPassosDesativados = [];
          fluxo.passosFluxo.forEach((passo) => {
            if (!passo.indAtivo) {
              idsPassosDesativados.push(passo.id);
            }
          });

          if (idsPassosDesativados.length === fluxo.passosFluxo.length) {
            this.$toast(this.$t('errors.fluxo.passo_elegivel_obrigatorio'));
            return;
          }

          params.idsPassosDesativados = idsPassosDesativados;
        }
      }

      iniciaFluxo(params, this.$resource)
        .then(() => {
          if (this.$route.params.from && this.$route.params.modalDashboard && this.$route.params.from === 'dashboard-inicial') {
            this.$router.push({ name: this.$route.params.from, params: { modalDashboard: this.$route.params.modalDashboard } });
          } else {
            this.$router.push({ name: 'planejamento-acao' });
          }
          this.$toast(this.$t('message.solicitacao_aprovacao'));
        })
        .catch((err) => {
          this.$error(this, err);
          this.$toast(this.$t('errors.fluxo.passo_elegivel_obrigatorio'));
        });
    },
    selecionaAprovadorPassoClienteFornecedor(idPasso, idUsuario) {
      const { idAcao } = this.$route.params;
      const params = {
        idAcao,
        idPasso,
        idUsuario,
      };
      const resource = this.workflowAcaoResource.associaUsuarioFornecedorAprovador;
      resource(params, params)
        .then(() => this.iniciarFluxo())
        .catch((err) => this.$error(this, err));
    },
    verificarUsuarioAprovador(idAcao) {
      if (this.acaoEdicao.passoGeral && this.acaoEdicao.passoGeral.status === 'AGUARDANDO_APROVACAO') {
        this.workflowAcaoResource.aprovador({ idAcao })
          .then((response) => {
            if (response.data.usuarioHabilitado) {
              this.aprovadorFluxo = true;
            }
          });
      }
    },
    abrirJustificativaAprovar() {
      this.justificativaObrigatoria = false;
      this.actionJustificativa = this.aprovar;
      this.isAprovacao = true;
      this.$refs.modalJustificativa.open();
    },
    aprovar(justificativa) {
      const { idAcao } = this.$route.params;
      this.workflowAcaoResource.aprovarPasso({ idAcao },
        { observacao: justificativa })
        .then(() => {
          this.$router.push({ name: 'planejamento-acao' });
          this.$toast(this.$t('message.acao_aprovada'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    abrirJustificativaReprovar() {
      this.justificativaObrigatoria = true;
      this.actionJustificativa = this.reprovar;
      this.isAprovacao = false;
      this.$refs.modalJustificativa.open();
    },
    reprovar(justificativa) {
      const { idAcao } = this.$route.params;
      this.workflowAcaoResource.reprovar({ idAcao },
        { observacao: justificativa })
        .then(() => {
          this.$router.push({ name: 'planejamento-acao' });
          this.$toast(this.$t('message.acao_reprovada'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    abrirJustificativaAnalise() {
      this.justificativaObrigatoria = true;
      this.actionJustificativa = this.enviarAnalise;
      this.isAprovacao = false;
      this.$refs.modalJustificativa.open();
    },
    enviarAnalise(justificativa) {
      const { idAcao } = this.$route.params;
      this.workflowAcaoResource.enviarAnalise({ idAcao },
        { observacao: justificativa })
        .then(() => {
          this.$router.push({ name: 'planejamento-acao' });
          this.$toast(this.$t('message.acao_enviada_analise'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    buscarStatusFluxo(idAcao) {
      this.workflowAcaoResource.status({ idAcao })
        .then((response) => {
          this.acaoEdicao.fluxo = response.data;
        });
    },
    mostrarRedirecionamento(item) {
      return item.solicitanteFluxo && item.status === 'AGUARDANDO_APROVACAO';
    },
    salvarPassosRedirecionamento(fluxo, acao) {
      const idsPassosDesativados = [];
      fluxo.passos.forEach((passo) => {
        if (!passo.indAtivo) {
          idsPassosDesativados.push(passo.id);
        }
      });

      if (idsPassosDesativados.length === fluxo.passos.length) {
        this.$toast(this.$t('errors.fluxo.passo_elegivel_obrigatorio'));
        return;
      }

      const params = {
        id_acao: acao.id,
        ids_passos_desativados: idsPassosDesativados,
      };

      atualizarPassos(params, this.$resource)
        .then(() => {
          this.$toast(this.$t('message.fluxo_redirecionado_sucesso'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    salvarAntesDeSolicitar() {
      const isSolicitandoAprovacao = true;
      this.acaoSalvar(isSolicitandoAprovacao).then(() => this.solicitarAprovacao());
    },
  },
  mounted() {
    const { novaAcao } = this.$router.currentRoute.params;
    if (novaAcao) {
      const numeroNd = this.acaoEdicao && this.acaoEdicao.passoGeral
        ? this.acaoEdicao.passoGeral.mapaCamposDinamicos.num_nota_debito : null;
      if (numeroNd) {
        this.msgConfirmacao = this.$t('message.acao_criada_sucesso_num_nd', { numNd: numeroNd });
        setTimeout(() => this.$refs.modalConfirmacao.open());
      }
    }
  },
};
</script>
