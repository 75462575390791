import PlanejamentoAcaoFormCampos from '@/spa/planejamento-acao/form/PlanejamentoAcaoFormCampos';
import PlanejamentoAcaoFormComportamento from '@/spa/planejamento-acao/form/PlanejamentoAcaoFormComportamento';

export default {
  componentes: {
    PlanejamentoAcaoFormCampos,
    PlanejamentoAcaoFormComportamento,
  },
  passosHabilitados(ref, configuracao, acao) { // eslint-disable-line
    return {
      geral: {
        habilitado: true,
        label: ref.$tc('label.geral', 1),
        componente: 'planejamento-acao-form-campos',
      },
      comportamento: {
        habilitado: true,
        label: ref.$tc('label.apuracao', 1),
        componente: 'planejamento-acao-form-comportamento',
      },
      contas: {
        habilitado: false,
      },
    };
  },
};
